<template>
	<div class="animate__animated animate__fadeIn">
		<top-bar :shadow="true">
			<div class="top-box">
				<img :src="logoImg" class="logo">
				<div class="menus-box" :style="fontColor">
					<div class="menus" v-for="(m,mi) in menus" :style="active == m.path ? {fontWeight:600} : ''" :key="mi" @mouseover="changeOver(m)" :class="m.hover ? 'hover-bg' : '' " @mouseleave="mouseLeave(m)" @click="changePath(m)">
						{{m.name}}
						<div class="dt-line" v-if="active == m.path" :style="dtBg"></div>
						<div class="se-me hover-bg" v-if="m.hover" >
							<div class="child" :style="fontColor" v-for="(c,ci) in m.child" :key="ci" @click.stop="changePath(c)">
								{{c.name}}
							</div> 
						</div>
					</div>
				</div>
			</div>
		</top-bar>
		<div class="header-tag">
			<span>解决方案 - 项目案例</span>
			<span style="color: #666;">--固碳预拌混凝土国内首次应用</span>
		</div>
		<div class="body-box">
			<div class="left-box">
<!-- 				<div class="tag-title">其他案例</div> -->
				<div class="dot"></div>
			</div>
			<div class="right-box">
				<div class="ri-title">固碳预拌混凝土国内首次应用 助力国家电网打造零碳变电站</div>
				<div class="content">
					近日，作为浙江省2023年新型电力系统试点项目，国家电网浙江电力公司在湖州安吉开发区新建的零碳变电站，首次在国内大规模商业化应用有碳封存属性的固碳预拌混凝土。经测算，在该项目中每使用1m³固碳预拌混凝土，将比传统产品减少20%以上的碳排放。此次应用不仅打造了在国网系统内二氧化碳矿化技术的“首台首套”，更是实现了CCUS（碳捕集、利用与封存）技术在国内预拌混凝土领域的突破。
				</div>
				<div class="content">
					<span style="text-align: center;display:block;font-weight: 600;">首个二氧化碳矿化利用预拌混凝土示范基地,年封存3000吨二氧化碳</span>
				</div>				
				<div class="content">
					2022年开始，CCUS科技公司清捕零碳与浙江大学能源清洁利用国家重点实验室组成的联合研发团队与浙江湖州一家混凝土搅拌站合作筹建了二氧化碳矿化利用预拌混凝土建材示范基地，开展了一系列关于固碳预拌混凝土的相关前期研究及生产示范应用，目标是以预拌混凝土及其制品为载体，实现二氧化碳永久固化封存，以解决日益严峻的气候变化问题。此次大规模应用于零碳变电站建设的固碳预拌混凝土便来自于此基地。
				</div>
				<div class="content">
					据相关技术人员介绍，固碳预拌混凝土的原理是以普通预拌混凝土为载体，将工业源排放的二氧化碳捕集后注入其中，注入后的二氧化碳通过微晶矿化过程并永久封存其中，同时改善了混凝土的工作性能并提高了抗压强度。
				</div>
				<img src="../static/project/zzz1.png" >
				<img src="../static/project/zzz2.png" >
				<div class="content">
					“在预拌混凝土中封存二氧化碳的好处是双重的，一方面二氧化碳被引入后形成碳酸化产物，一旦混凝土凝结硬化，它就会被永久封存；另一方面，碳酸化产物填充了混凝土微孔隙，改善了混凝土孔隙结构，使它变得更坚固。这意味着达到同样的抗压强度，水泥的用量可以减少5%~10%，这样可以抵消碳的额外成本。这种环保混凝土具有经济和环境双重收益，未来还能产生额外的碳税价值”。
				</div>
				<div class="content">
					在该示范基地内，技术人员通过自主研发的“预拌混凝土二氧化碳微晶化矿化技术体系”，对预拌混凝土原料配方、生产设备等环节进行了改造升级。目前，该条示范生产线可实现年产80万立方米的固碳预拌混凝土，满足C20~C50等不同型号产品。该条生产线一年较传统工艺可减碳约5.9万吨，每年可实现二氧化碳封存利用约3000吨。
				</div>
				<div class="content">
					<span style="text-align: center;display:block;font-weight: 600;">减少隐含碳  CCUS技术在预拌混凝土实现突破</span>
				</div>
				<img src="../static/project/zzz3.png" >
				<img src="../static/project/zzz4.png" >
				<div class="content">
					此次应用的清捕零碳创新除碳技术是减少建筑隐含碳或建筑材料生产和运输碳排放的直接方法。该技术可以在不中断操作的情况下改装到混凝土搅拌站中，并与现有的配料过程无缝集成。借助该项技术，建筑商、工程承包商、开发商等可以减少建筑隐含碳，而不牺牲建筑质量。
				</div>
				<div class="content">
					在此次项目中，由权威检测机构CTI华测，严格按照《PAS 2050:2011商品和服务在生命周期内的温室气体排放评价规范》,出具CCUS技术实现二氧化碳封存利用的固碳预拌混凝土碳足迹报告显示，与Ecoinvent 3.9.1数据库中传统工艺生产的预拌混凝土相比较，每立方米可减少近70kg的二氧化碳，相当于减少了预拌混凝土在生产阶段20%以上的碳排放。
				</div>
				<div class="content">
					2021年我国预拌混凝土产量达32.93亿立方米，通过此项技术每年的二氧化碳封存利用潜力将达到约1200万吨。
				</div>
				<div class="content">
					此次在零碳变电站建设中大规模商业应用固碳预拌混凝土，意味着CCUS技术在预拌混凝土方向实现突破，打通了能源—工业—建筑多个行业的“双碳”产业链条，为CCUS技术发展、产业化布局提供了更多可能性，也将为未来建筑隐含碳的减少增添新的路径。
				</div>
				<div class="content">
					<span style="text-align: center;display:block;font-weight: 600;">国家电网零碳变电站应用固碳预拌混凝土</span>
				</div>
				<div class="content">
					据了解，此次国网浙江省电力有限公司湖州供电公司筹建的安吉城北变电站，是浙江省2023年新型电力系统试点项目，通过固碳预拌混凝土技术、BIPV光伏一体化墙板技术、二氧化碳环保气体GIS技术等将打造国网电力系统的零碳示范工程。
				</div>
				<img src="../static/project/zzz5.png" >
				<div class="content">
					据湖州安吉城北110千伏变电站工程业主项目经理金烨介绍，“为保证此次固碳预拌预拌混凝土在项目中成功应用，浙江大学、清捕零碳联合研发团队与国网浙江省电力有限公司经济技术研究院、湖州供电公司的工程技术人员在前期针对产品性能等方面做了大量的测试、论证。该项技术此次的成功应用，也对未来固碳混凝土技术在电网系统内部及其他基建设施上的应用有一定的示范意义”。
				</div>
			</div>
		</div>
		<div style="width: 100%;margin-top: 251px;">
			<footer-bar></footer-bar>
		</div>
	</div>
</template>

<script>
	import topBar from '@/components/header-bar.vue'
	import utils from '@/utils/common.js'
	import footerBar from '@/components/footer-bar.vue'
	export default{
		components:{
			topBar,
			footerBar
		},
		data(){
			return{
				name:'solution',
				menus:utils.menus,
				active:'solution',
				swiperIndex:1,
			}
		},
		computed:{
			logoImg(){
				// console.log(this.swiperIndex,'lll')
				if(this.swiperIndex == 0){
					return require('../static/qlogo.png')
				}
				else if(this.swiperIndex == 1){
					return require('../static/qlogo.png')
				}
				else if(this.swiperIndex == 2){
					return require('../static/qlogo.png')
				}
				else if(this.swiperIndex == 3){
					return require('../static/home/logo2.png')
				}
				else if(this.swiperIndex == 4){
					return require('../static/home/logo2.png')
				}
				else if(this.swiperIndex == 5){
					return require('../static/wlogo.png')
				}
				else if(this.swiperIndex == 6){
					return require('../static/home/logo2.png')
				}
				
			},
			fontColor(){
				if(this.swiperIndex == 0){
					return {
						color:'#fff'
					}
				}
				else if(this.swiperIndex == 1){
					return {
						color:'#333'
					}
				}
				else if(this.swiperIndex == 2){
					return {
						color:'#fff'
					}
				}
				else if(this.swiperIndex == 3){
					return {
						color:'#333'
					}
				}
				else if(this.swiperIndex == 4){
					return {
						color:'#333'
					}
				}
				else if(this.swiperIndex == 5){
					return {
						color:'#fff'
					}
				}
				else if(this.swiperIndex == 6){
					return {
						color:'#333'
					}
				}
			},
			dtBg(){
				if(this.swiperIndex == 0){
					return {
						background:'#fff'
					}
				}
				else if(this.swiperIndex == 1){
					return {
						background:'#333'
					}
				}
				else if(this.swiperIndex == 2){
					return {
						background:'#fff'
					}
				}
				else if(this.swiperIndex == 3){
					return {
						background:'#333'
					}
				}
				else if(this.swiperIndex == 4){
					return {
						background:'#333'
					}
				}
				else if(this.swiperIndex == 5){
					return {
						background:'#fff'
					}
				}
				else if(this.swiperIndex == 6){
					return {
						background:'#333'
					}
				}
			}
		},
		methods:{
			changeOver(m){
				// console.log(m)
				if(m.path == 'solution'){
					m.hover = true
					this.active = m.path
				}
			},
			mouseLeave(m){
				this.menus.forEach(item=>item.hover = false)
				this.active = this.name
			},
			changePath(item){
				if( item.path != 'solution'){
					this.$router.push({
						name:item.path
					})
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.wrap {
	    width: 100%;
	    height: 100vh;
	    overflow: hidden;
	    // background: #eeeeee;
		margin: 0;
		padding: 0;
		
	}
		.active-h{
			font-weight: 600;
		}
	.top-box{
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		// background: #007AFF;
		.logo{
			width: 42px;
			height: 47px;
			margin-left: 11.25rem;
		}
		.menus-box{
			height: 100%;
			display: flex;
			align-items: center;
			margin-left: 510px;
			position: relative;
		}
		.menus{
			height: 100%;
			width: 114px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			// margin-right: 60px;
			font-size: 14px;
			font-weight: 400;
			
			cursor: pointer;
			box-sizing: border-box;
			position: relative;
			.dt-line{
				width: 20px;
				height: 4px;
				// background: #FFFFFF;
				position: absolute;
				bottom: 16px;
			}
			.se-me{
				position: absolute;
				width: 114px;
				height: 108px;
				bottom: -108px;
			}
			.child{
				width: 100%;
				height: 54px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 14px;
				font-weight: 400;
				// color: #FFFFFF;
			}
		}
	}
	
	.header-tag{
		display: flex;
		align-items: center;
		font-size: 14px;
		font-weight: 400;
		color: #075AD3;
		margin-top: 130px;
		margin-left: 175px;
	}
	.body-box{
		width: 100%;
		display: flex;
		align-items: flex-start;
		// justify-content: space-between;
		padding: 0 0 0 175px;
		box-sizing: border-box;
		margin-top: 50px;
		.left-box{
			width: 299px;
			height: 357px;
			background: #FAFBFF;
			padding: 24px 24px 0 24px;
			box-sizing: border-box;
			position: relative;
			margin-right: 60px;
			.tag-title{
				font-size: 20px;
				font-weight: 500;
				color: #1A1A1A;
				margin-bottom: 10px;
			}
			.new-box{
				width: 100%;
				min-height: 64px;
				// sbackground: red;
				
				font-size: 18px;
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				font-weight: 400;
				color: #1A1A1A;
			}
			.new-border{
				border-bottom: 1px solid #E6E6E6;
			}
			.dot{
				width: 18px;
				height: 18px;
				background: linear-gradient(320deg, rgba(103, 165, 255, 0) 0%, #77AEFF 100%);
				position: absolute;
				border-radius: 50%;
				top: 20px;
				left: 16px;
			}
		}
		.right-box{
			width: 726px;
			padding-left: 60px;
			box-sizing: border-box;
			.ri-title{
				font-size: 28px;
				font-weight: 500;
				color: #1A1A1A;
				margin-bottom: 32px;
				text-align: center;
			}
			.content{
				font-size: 16px;
				font-weight: 400;
				color: #666666;
				text-indent: 30px;
				margin-bottom: 24px;
			}
			img{
				width: 640px;
				height: 100%;
				margin-bottom: 24px;
				margin-left: 20px;
			}
			.imgs{
				width: 726px;
				height: 1000px;
			}
		}
	}
</style>
